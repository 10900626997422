import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { TwoColumnLayout, SplashPage, Redirect } from '../../components/Layout'
import { Helmet } from 'react-helmet'
import GoBackButton from './_components/GoBackButton'
import Container from './_components/Container'
import Title from './_components/Title'
import Description from './_components/Description'
import SignUp from '../../components/Form/SignUp'
import { navigate } from 'gatsby'
import ROUTES from '../../config/routes'
import LangPicker from '../../components/LanguagePicker'
import signUpImagePNG from '../../static/eastwood/eastwood-riding-in-the-park.png'
import Link from '../../components/Link/GatsbyLink'
import useAuth from '../../state/auth/useAuth'

const SignUpPage = () => {
  const { t } = useTranslation('pages')

  const { isLoaded, isGuest } = useAuth()

  if (isLoaded === false) {
    return <SplashPage />
  }

  if (isGuest === false) {
    return <Redirect to={ROUTES.home} />
  }

  return (
    <TwoColumnLayout
      leftClassnames={'bg-white'}
      rightClassnames={'bg-gray-200'}
      rightChildren={
        <div>
          <Link
            to={ROUTES.signin}
            className={
              'btn btn-sm btn-primary-outline w-24 absolute right-0 top-0 mr-5 lg:mr-8 mt-5 lg:mt-8 z-10'
            }
          >
            {t('signup.loginLink')}
          </Link>

          <img
            src={signUpImagePNG}
            alt={'Sign Up - Academator'}
            className={'w-100 absolute bottom-0 scale-x-flip mb-20'}
          />

          <LangPicker
            containerClass={'absolute bottom-0 right-0 mb-12 mr-12 text-sm text-gray-700'}
          />
        </div>
      }
    >
      <Helmet>
        <title>{t('signup.seo.title')}</title>
      </Helmet>

      <GoBackButton onClick={() => navigate(ROUTES.goBack)} />

      <Link
        to={ROUTES.signin}
        className={
          'btn btn-sm btn-primary-outline w-24 absolute right-0 top-0 mr-5 lg:mr-8 mt-5 lg:mt-8 lg:hidden'
        }
      >
        {t('signup.loginLink')}
      </Link>

      <Container>
        <Title>{t('signup.title')}</Title>
        <Description>{t('signup.subtitle')}</Description>

        <SignUp />
      </Container>
    </TwoColumnLayout>
  )
}

export default SignUpPage
