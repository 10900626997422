import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  SolidRow,
  SolidError,
  SIZES,
  SolidFloatingLabelInput,
  SolidSelect,
} from '../../Input/solid'
import LoadingButton from '../../Button/LoadingButton'
import { useTranslation } from 'react-i18next'
import api from '../../../apis'
import { notificationSuccess } from '../../../utils/notification'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Trans } from 'react-i18next'
import ROUTES from '../../../config/routes'
import Link from '../../Link/GatsbyLink'

const TYPE_TUTOR = 'tutor'
const TYPE_STUDENT = 'student'
const TYPE_PARENT = 'parent'

const accountTypes = [
  {
    value: TYPE_TUTOR,
    label: 'I am a Tutor',
  },
  {
    value: TYPE_STUDENT,
    label: 'I am a Student',
  },
  {
    value: TYPE_PARENT,
    label: 'I am a Parent',
  },
]

type SignUpType = {
  onSuccess: () => void
}

const SignUp = ({ onSuccess }: SignUpType) => {
  const { t } = useTranslation('forms')

  const [isSubmitting, setSubmitting] = useState(false)

  const [name, setName] = useState('')
  const [nameError, setNameError] = useState('')
  const [lastName, setLastName] = useState('')
  const [lastNameError, setLastNameError] = useState('')
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState('')
  const [password, setPassword] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [isPasswordVisible, setIsPasswordVisible] = useState(false)
  const [accountType, setAccountType] = useState(TYPE_TUTOR)

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    setSubmitting(true)

    try {
      await api.auth.postSignUp({
        type: accountType,
        email: email,
        firstName: name,
        lastName: lastName,
        password: password,
      })

      setSubmitting(false)
      notificationSuccess(t('signup.success'))
      setName('')
      setLastName('')
      setEmail('')
      setPassword('')
      onSuccess()
    } catch (error) {
      const status = error.response.status

      if (status === 422) {
        setSubmitting(false)

        return error.handle422({
          name: setNameError,
          lastName: setLastNameError,
          password: setPasswordError,
          email: setEmailError,
        })
      }

      error.handleDefault()
    }
  }

  return (
    <form onSubmit={onSubmit} autoComplete="off">
      <SolidRow>
        <SolidSelect
          size={SIZES.LARGE}
          value={accountType}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAccountType(e.target.value)}
          disabled={isSubmitting}
          required={true}
          options={accountTypes}
        />
      </SolidRow>

      <div className={'flex flex-col sm:flex-row'}>
        <SolidRow className={'sm:w-1/2 sm:mr-2'}>
          <SolidFloatingLabelInput
            label={t('signup.first-name.label')}
            size={SIZES.LARGE}
            value={name}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setName(e.target.value)
              setNameError('')
            }}
            isInvalid={nameError.length > 0}
            disabled={isSubmitting}
            required={true}
            placeholder={t('signup.first-name.placeholder')}
          />

          {nameError && <SolidError>{nameError}</SolidError>}
        </SolidRow>

        <SolidRow className={'sm:w-1/2 sm:ml-2'}>
          <SolidFloatingLabelInput
            label={t('signup.second-name.label')}
            size={SIZES.LARGE}
            value={lastName}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setLastName(e.target.value)
              setLastNameError('')
            }}
            isInvalid={lastNameError.length > 0}
            disabled={isSubmitting}
            required={true}
            placeholder={t('signup.second-name.placeholder')}
          />

          {lastNameError && <SolidError>{lastNameError}</SolidError>}
        </SolidRow>
      </div>

      <SolidRow>
        <SolidFloatingLabelInput
          label={t('signup.email.label')}
          size={SIZES.LARGE}
          value={email}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setEmail(e.target.value)
            setEmailError('')
          }}
          isInvalid={emailError.length > 0}
          disabled={isSubmitting}
          required={true}
          type={'email'}
          autoComplete={'off'}
          placeholder={t('signup.email.placeholder')}
        />

        {emailError && <SolidError>{emailError}</SolidError>}
      </SolidRow>

      <SolidRow>
        <SolidFloatingLabelInput
          label={t('signup.password.label')}
          size={SIZES.LARGE}
          value={password}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setPassword(e.target.value)
            setPasswordError('')
          }}
          isInvalid={passwordError.length > 0}
          disabled={isSubmitting}
          required={true}
          type={isPasswordVisible ? 'text' : 'password'}
          placeholder={t('signup.password.placeholder')}
          autoComplete={'new-password'}
          rightContent={
            <FontAwesomeIcon
              fixedWidth
              onClick={() => {
                setIsPasswordVisible(!isPasswordVisible)
              }}
              className={'text-xl cursor-pointer text-gray-400 mt-1'}
              icon={['fal', isPasswordVisible ? 'eye-slash' : 'eye']}
            />
          }
        />

        {passwordError && <SolidError>{passwordError}</SolidError>}
      </SolidRow>

      <LoadingButton
        type={'submit'}
        submitting={isSubmitting}
        className={'btn btn-primary btn-lg w-full'}
        label={t('signup.submit.label')}
      />

      <div className={'mt-6 text-xs text-gray-700'}>
        <Trans i18nKey={'signup.notice.main'} t={t}>
          By confirming your email, you agree to our{' '}
          <Link className={'font-medium'} to={ROUTES.terms}>
            {{ terms: t('signup.notice.terms') }}
          </Link>{' '}
          and that you have read and understood our{' '}
          <Link className={'font-medium'} to={ROUTES.privacy}>
            {{ privacy: t('signup.notice.privacy') }}
          </Link>
          .
        </Trans>
      </div>
    </form>
  )
}

SignUp.propTypes = {
  onSuccess: PropTypes.func,
}

SignUp.defaultProps = {
  onSuccess: () => null,
}

export default SignUp
